import { defineStore } from "pinia";
import axios, { type AxiosResponse } from "axios";
import type CartProduct from "~/types/CartProduct";
import { useUserStore } from "~/store/user";
import { useCartPrices } from "~/composables/cart";

export const useCartStore = defineStore("cartStore", {
    persist: false,
    state: () => ({
        items: [] as CartProduct[],
        summary: {} as {
            subtotal: number;
            subtotalForShipItems: number;
            shipping: number;
            tax: number;
            total: number;
        },
        qty: 0,
        maxQty: 100000,
        loading: false,
    }),
    getters: {},
    actions: {
        calculateTotalQty() {
            this.qty = 0;
            for (const item of this.items) {
                this.qty += item.qty;
            }
        },
        updateCartFromServer(response: AxiosResponse<any> | null) {
            if (Array.isArray(response?.data) && response.data.length >= 2) {
                this.items = response.data[0];
                this.summary = response.data[1];
                this.calculateTotalQty();
            } // If response is empty, it means the cart is empty/null
            else {
                this.items = [];
                this.summary = {
                    subtotal: 0,
                    subtotalForShipItems: 0,
                    shipping: 0,
                    tax: 0,
                    total: 0,
                };
                this.qty = 0;
            }
        },

        async fetchCartAndSummary(refresh = false, state = "", zip = "") {
            try {
                if (import.meta.client) {
                    if (refresh || this.items.length <= 0 || !this.items[0].product_id) {
                        this.loading = true;
                        const config = useRuntimeConfig();
                        const url = `${config.public.STATAMIC_API}/store/cart?state=${state}&zip=${zip}`;
                        const response = await axios.get(url);

                        this.updateCartFromServer(response);
                        setTimeout(() => {
                            this.loading = false;
                        }, 1000);
                    }
                }

                return this.items;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
            return [];
        },
        async fetchCartItem(productId: number, orderType = "", refresh = false) {
            try {
                // If the cart is empty or we need to refresh, fetch the cart
                if (refresh || this.qty <= 0) {
                    await this.fetchCartAndSummary();
                }
                // Find the item in the cart
                for (const item of this.items) {
                    if (item.product_id === productId && item.order_type === orderType) {
                        return item;
                    } else if (item.product_id === productId && orderType === "") {
                        return item;
                    }
                }
            } catch (error) {
                console.log(error);
            }
            return null;
        },
        async upsertItem(productId: number, qty: number, orderType = "") {
            try {
                const state = useUserStore().userState;
                const zip = useUserStore().userZip;

                const config = useRuntimeConfig();
                const url = `${config.public.STATAMIC_API}/store/cart/${productId}`;
                const response = await axios.post(url, {
                    qty,
                    orderType,
                    state,
                    zip,
                });

                this.updateCartFromServer(response);

                return this.items;
            } catch (error) {
                console.log(error);
            }
            return [];
        },
        async deleteItem(productId: number, orderType = "") {
            try {
                const config = useRuntimeConfig();
                const url = `${config.public.STATAMIC_API}/store/cart/${productId}`;
                const data = { orderType: orderType ?? useCartPrices().B_W_PRINT };
                const response = await axios.delete(url, { data });

                this.updateCartFromServer(response);

                return this.items;
            } catch (error) {
                console.log(error);
            }
            return [];
        },
    },
});
