import VueGoogleMaps from "@fawmi/vue-google-maps";

export default defineNuxtPlugin({
    async setup(nuxtApp) {
        const config = useRuntimeConfig();

        nuxtApp.vueApp.use(VueGoogleMaps, {
            load: {
                key: config.public.GOOGLE_API_KEY,
                libraries: "places", // This is required if you use the Autocomplete plugin
            },
            autobindAllEvents: true,
        });
    },
});
