import { defineStore } from "pinia";
import { navigateTo } from "#app";
import { $fetch } from "ofetch";
import axios from "axios";
import { useCheckoutStore } from "~/store/checkout";
import type User from "~/types/User";

export const useUserStore = defineStore("userStore", {
    persist: {
        storage: piniaPluginPersistedstate.cookies({
            sameSite: "strict",
            maxAge: 60 * 60 * 12, // 12 hours
        }),
    },
    state: () => ({
        executedFetchUser: false,
        lastUserFetched: Date.now(),
        user: {} as User,
        isLoggedIn: false,
        userState: "",
        userZip: "",
        userCounty: "",

        searchText: "",
        loginRedirect: "",
    }),
    getters: {},
    actions: {
        needToRefreshUser() {
            const inXMilliseconds = 15 * 60 * 1000; // 15 minutes
            const millisecondsSince = Date.now() - this.lastUserFetched;

            if (millisecondsSince > inXMilliseconds) {
                return true;
            }

            return false;
        },
        async fetchUser(logOutUnAuthUser = true) {
            const refresh = this.needToRefreshUser();

            try {
                if (this.executedFetchUser && this.user && !refresh) {
                    return this.user;
                }

                if (import.meta.client && (!this.executedFetchUser || refresh)) {
                    const config = useRuntimeConfig();
                    const response = await axios.get(config.public.STATAMIC_API + "/user");

                    if (response.status == 200 && response.data && Object.keys(response.data).length > 0) {
                        this.updateUser(response.data);
                        this.lastUserFetched = Date.now();
                        this.executedFetchUser = true;
                    } // Clear out user data just in case
                    else {
                        this.removeUser(logOutUnAuthUser);
                    }
                }
            } catch (e: any) {
                console.log("e:", e);
                // User not logged in will return 401 unauthorized. Redirect if requested.
                if (e.response?.status === 401) {
                    this.removeUser(logOutUnAuthUser);
                }
            }

            return this.user;
        },
        updateUser(user: any) {
            this.user = user;
            this.isLoggedIn = true;
        },
        removeUser(logOutUnAuthUser = true) {
            useCheckoutStore().clearEnteredData();
            this.user = null;
            this.isLoggedIn = false;
            this.executedFetchUser = false;
            if (logOutUnAuthUser) {
                navigateTo(`/home/${this.userState ?? "ga"}`);
            }
        },
        updateUserState(state: string) {
            if (state) {
                this.userState = state.toLowerCase();
            }
        },
        updateUserZip(zip: string) {
            if (zip) {
                this.userZip = zip;
            }
        },
        async fetchUserState() {
            try {
                if (this.userState) {
                    return this.userState;
                }

                if (import.meta.client && (!this.userState || !this.userZip)) {
                    const response = await $fetch("https://ipapi.co/json/");

                    // In the case this is being view outside the US, default to GA
                    if (response.country_code != "US") {
                        this.updateUserState("ga");
                        this.updateUserZip("30033");
                    } else if (response.region_code) {
                        this.updateUserState(response.region_code);
                        this.updateUserZip(response.postal);
                    }
                }
            } catch (e: any) {
                //console.log("e:", e);
            }

            return this.userState;
        },
        updateLoginRedirect(path: string) {
            if (path != "/login") {
                this.loginRedirect = path;
            }
        },
    },
});
