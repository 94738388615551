import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_SbZ1I_SZK_HPDkuT2uYRt3G_2yoWlJ18n7cXg5Kbim8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.23.0_jiti@2.4_3edaa09295d1f0553441c8adc7a86c9f/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Jh6mn97jGsP8wGhI_Kz1Y7QoJxJ5TjI7ZPv8HhLDjgE from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.23.0_jiti@2.4_3edaa09295d1f0553441c8adc7a86c9f/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_lo4P_M25nr9YQEBXigARbmLQ3YxPNPApsY_DWvixvnY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.23.0_jiti@2.4_3edaa09295d1f0553441c8adc7a86c9f/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_PY81AZ1_l_Q1Nghe0qxW3DFIFbRkx1nsjzh8R_oE224 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.23.0_jiti@2.4_3edaa09295d1f0553441c8adc7a86c9f/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Q9IrHS3PIQiZt_ejZgLFoBGIns8brR3DAxHowaIFW4s from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.23.0_jiti@2.4_3edaa09295d1f0553441c8adc7a86c9f/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uKeA57W5IVlsYKpoJaf8I8sEBGOgKB5nASQA0ReJ6pk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.23.0_jiti@2.4_3edaa09295d1f0553441c8adc7a86c9f/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SSxizqHSKNYHrsA7sOpWNPCCCO9ap84swM2Sl6ap2cc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.23.0_jiti@2.4_3edaa09295d1f0553441c8adc7a86c9f/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_qAQaJkp18lRw5zwYy5tf3yNWEZ_aBeJ4i1EF1g1GElk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.23.0_jiti@2.4_3edaa09295d1f0553441c8adc7a86c9f/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_NPi_Ixo1sLCQtQhYgcq9HdVO7xR0RGTjgeae7FdmR4E from "/opt/buildhome/repo/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescr_0207495bb835a6f5ae7860b54eb3353f/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU from "/opt/buildhome/repo/plugins/error-handler.ts";
import floating_vue_IzZvwu4d0V2PmLzX5FM8OeN_n9KoM824GlcNgFB4ru0 from "/opt/buildhome/repo/plugins/floating-vue.ts";
import sentry_mW3XGrtPCuZcBmJuVnT9v_bqG2TVSyf5dPs2Yj6o6FM from "/opt/buildhome/repo/plugins/sentry.ts";
import vueGoogleMaps_K6sv2NTkUHNA0vyej32YAi_299wZ2p0OeyH69GqhbYE from "/opt/buildhome/repo/plugins/vueGoogleMaps.ts";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_SbZ1I_SZK_HPDkuT2uYRt3G_2yoWlJ18n7cXg5Kbim8,
  unhead_Jh6mn97jGsP8wGhI_Kz1Y7QoJxJ5TjI7ZPv8HhLDjgE,
  router_lo4P_M25nr9YQEBXigARbmLQ3YxPNPApsY_DWvixvnY,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client_PY81AZ1_l_Q1Nghe0qxW3DFIFbRkx1nsjzh8R_oE224,
  navigation_repaint_client_Q9IrHS3PIQiZt_ejZgLFoBGIns8brR3DAxHowaIFW4s,
  check_outdated_build_client_uKeA57W5IVlsYKpoJaf8I8sEBGOgKB5nASQA0ReJ6pk,
  chunk_reload_client_SSxizqHSKNYHrsA7sOpWNPCCCO9ap84swM2Sl6ap2cc,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_qAQaJkp18lRw5zwYy5tf3yNWEZ_aBeJ4i1EF1g1GElk,
  plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4,
  plugin_NPi_Ixo1sLCQtQhYgcq9HdVO7xR0RGTjgeae7FdmR4E,
  error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU,
  floating_vue_IzZvwu4d0V2PmLzX5FM8OeN_n9KoM824GlcNgFB4ru0,
  sentry_mW3XGrtPCuZcBmJuVnT9v_bqG2TVSyf5dPs2Yj6o6FM,
  vueGoogleMaps_K6sv2NTkUHNA0vyej32YAi_299wZ2p0OeyH69GqhbYE
]