import { useUserStore } from "~/store/user";

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (process.client) {
        // Delay half a second for component to mount before checking user. User data is stored in cookies and
        // it takes a moment for the cookies to be read.
        setTimeout(async () => {
            // Protect account route
            if (to.fullPath.includes("/account")) {
                useUserStore().executedFetchUser = false; // Reset fetch user flag so we can check again on the server side
                const user = await useUserStore().fetchUser();
                if (!user) {
                    return navigateTo("/login");
                }
            } else if (to.fullPath.includes("/login")) {
                useUserStore().executedFetchUser = false; // Reset fetch user flag so we can check again on the server side
                const user = await useUserStore().fetchUser(false);
                if (user) {
                    const loginRedirect = useUserStore().loginRedirect;
                    if (loginRedirect) {
                        return navigateTo(loginRedirect);
                    }
                    return navigateTo("/account/details");
                }
            }
        }, 500);

        return;
    }
});
