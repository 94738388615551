import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.23.0_jiti@2.4_3edaa09295d1f0553441c8adc7a86c9f/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/buildhome/repo/middleware/auth.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.23.0_jiti@2.4_3edaa09295d1f0553441c8adc7a86c9f/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  guest: () => import("/opt/buildhome/repo/middleware/guest.ts")
}